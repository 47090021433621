import React, { useContext, useState } from 'react'
import Accordion from '../../components/CartAccordion/CartAccordion'
import i18next from 'i18next'
import { navigate } from 'gatsby'
import Link from '../../components/Link/Link'
import styles from './styles.module.scss'
import NotEnoughProducts from '../../screens/NotEnoughProducts/NotEnoughProducts'
import { StoreContext } from '../../store/context'

const CartPage: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const goTo = (e: React.MouseEvent<HTMLAnchorElement>) => {
    return navigate(e.currentTarget.pathname)
  }

  const {
    state: { shoppingCart }
  } = useContext(StoreContext)
  const { totalAmount, totalQuantityOfProducts } = shoppingCart

  const cartSum = totalAmount.toString().replace('.', ',')

  const toggleModal = () => {
    return setShowModal(!showModal)
  }

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    if (totalQuantityOfProducts >= 10) {
      return goTo(e)
    } else {
      toggleModal()
    }
  }
  return (
    <section className={styles.wrapper}>
      <div className={styles.top}>
        <h1 className={styles.title}>{i18next.t('checkout.cart')}</h1>
        <Link className={styles.goBack} icon href="/butiken" onClick={goTo}>
          {i18next.t('checkout.continue')}
        </Link>
      </div>

      <Accordion />

      <div className={styles.total}>
        <p>{i18next.t('checkout.tot')}</p>
        <p className={styles.totalAmount}>
          {i18next.t('checkout.sum', { cartSum })}
          <span>{i18next.t('checkout.kg')}</span>
        </p>
      </div>

      <div className={styles.ctaWrapper}>
        <Link button="green" href="/resultat" onClick={handleClick}>
          {i18next.t('checkout.checkout')}
        </Link>
      </div>
      <NotEnoughProducts open={showModal} toggleModal={toggleModal} />
    </section>
  )
}

export default CartPage
