import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import Link from '../../components/Link/Link'
import styles from './styles.module.scss'
import Modal from '../../components/Modal/Modal'

interface Props {
  open: boolean
  toggleModal: () => void
}

interface NotEnoughProducts {
  title: string
  text: string
  cta: string
  bg_image: {
    source_url: string
  }
}

interface StaticQueryProps {
  allWordpressPage: {
    nodes: Array<{ acf: { not_enough_products: NotEnoughProducts } }>
  }
}

const query = graphql`
  query notEnoughQuery {
    allWordpressPage(filter: { slug: { eq: "screens" } }) {
      nodes {
        acf {
          not_enough_products {
            cta
            text
            title
            bg_image {
              source_url
            }
          }
        }
      }
    }
  }
`

const NotEnoughProducts: React.FC<Props> = ({ open, toggleModal }) => {
  const data: StaticQueryProps = useStaticQuery(query)
  if (!data) return null

  const fields: NotEnoughProducts = data.allWordpressPage.nodes[0].acf.not_enough_products

  const {
    title,
    text,
    cta,
    bg_image: { source_url }
  } = fields
  return (
    <Modal
      className={styles.notEnoughProductsModal}
      backgroundImage={`linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(${source_url}`}
      open={open}
      onClose={toggleModal}
    >
      <div className={styles.modalContent}>
        <p>{title}</p>
        <p>{text}</p>
        <Link className={styles.cta} icon href="/butiken" onClick={toggleModal}>
          {cta}
        </Link>
      </div>
    </Modal>
  )
}

export default React.memo(NotEnoughProducts)
