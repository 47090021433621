import React from 'react'
import { Helmet } from 'react-helmet'
import IndexLayout from '../layouts'
import CartView from '../views/CartView/CartView'

const CartPage: React.FC = () => {
  const meta = {
    title: 'Varukorgen | Klimatbutiken',
    description: 'Din varukorg'
  }
  return (
    <IndexLayout maxWidthWrapper>
      <Helmet title={meta.title}>
        <meta property="og:title" content={meta.title} />
        <meta name="og:description" content={meta.description} />
        <meta name="description" content={meta.description} />
      </Helmet>
      <CartView />
    </IndexLayout>
  )
}

export default CartPage
