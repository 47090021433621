import i18next from 'i18next'
import React, { useContext } from 'react'
import { useState } from 'react'
import { pricePerType } from '../../store/Store.helpers'
import { StoreProducts } from '../../models/Products'
import { StoreContext } from '../../store/context'
import { Types } from '../../store/reducers/ShoppingCartReducer'
import { ArrowDown, Icon } from '../Icon'
import CartItem from './CartItem/CartItem'
import styles from './styles.module.scss'

const Accordion: React.FC = () => {
  const [expanded, setExpanded] = useState(false)
  const handleClick = () => setExpanded(!expanded)

  const cart = useContext(StoreContext)

  const handleQuantityChange = (product: StoreProducts, quantity: number) => {
    product.quantity = quantity
    return cart.dispatch({ type: Types.UPDATE_QUANTITY, payload: { product } })
  }

  const handleRemoval = (product: StoreProducts) => {
    return cart.dispatch({ type: Types.REMOVE_PRODUCT, payload: { product } })
  }
  const shoppingCart = cart.state.shoppingCart.cart
  return (
    <div className={styles.listItem}>
      <button className={styles.toggleButton} onClick={handleClick}>
        {i18next.t('checkout.products')} <Icon icon={ArrowDown} size="small" rotate={expanded} />
      </button>
      {expanded && (
        <ol className={styles.list}>
          {shoppingCart &&
            shoppingCart.map((product) => {
              const { quantity, title, price_per_product, climate_impact, id = '' } = product
              return (
                <CartItem
                  key={id}
                  removeProduct={() => handleRemoval(product)}
                  onQuantityChange={(quantity) => handleQuantityChange(product, quantity)}
                  quantity={quantity || 0}
                  price={pricePerType(product) || '0'}
                  title={title}
                  climate_impact={climate_impact}
                  price_per_product={price_per_product}
                />
              )
            })}
        </ol>
      )}
      <p className={styles.totArticles}>
        {' '}
        {i18next.t('checkout.articles', { articleSum: cart.state.shoppingCart.totalQuantityOfProducts })}
      </p>
    </div>
  )
}

export default React.memo(Accordion)
