import React from 'react'
import { QuantityPicker } from '../../QuantityPicker/QuantityPicker'
import { Product } from '../../../models/Products'
import SvgClose from '../../Icon/Icons/Close'
import { Icon } from '../../Icon'
import styles from './styles.module.scss'
import { Badge } from '../../Badge/Badge'
import i18next from 'i18next'

type Prod = Pick<Product, 'title' | 'price_per_product' | 'climate_impact'>
interface Props extends Prod {
  price: string
  quantity: number
  onQuantityChange: (value: number) => void
  removeProduct: () => void
}
const CartItem: React.FC<Props> = ({ title, quantity, price, onQuantityChange, removeProduct, climate_impact }) => {
  const handleChange = (quantity: number) => onQuantityChange(quantity)
  return (
    <li className={styles.cartItem}>
      <p className={styles.title}>{title}</p>
      <div className={styles.info}>
        <QuantityPicker onChange={handleChange} max={7} label={title} name={title} value={quantity} />
        <Badge className={styles.badge} size="smaller" impact={climate_impact} />
        <p className={styles.price}>
          {i18next.t('checkout.sum', { cartSum: price.replace('.', ',') })} <span> {i18next.t('checkout.kg')}</span>
        </p>
        <Icon onClick={removeProduct} size="small" icon={SvgClose} />
      </div>
    </li>
  )
}

export default React.memo(CartItem)
